import React from "react"
import Layout from "../components/common/layout/layout"
import HeroHeader from "../components/sections/hero-header"

const ContactThanksPage = ({ location }) => (
  <Layout title="Thanks a lot for your message" location={location}>
    <HeroHeader
      title="Thanks a lot for your message"
      subTitle="We'll contact you as soon as possible"
      backgroundImage="confirm-back.png"
      foregroundImage="confirm-front.png"
      middleImage="middle-shape.png"
      actionTitle="Back to homepage"
      actionTo="/"
    />
  </Layout>
)

export default ContactThanksPage
